.single-case-message-section {
  margin-bottom: 25px;
}

.single-case-message-wrapper {
  height: 400px;
  overflow-y: auto;
  padding-top: 10px;
  border: 1px solid #d8d8d8;
  padding-bottom: 10px;
  margin-top: 5px;
  border-radius: 4px;
}
