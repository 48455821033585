.doctor-history-row {
  margin-bottom: 20px;
}

.doctor-history-row .doctor-history-title {
  font-weight: bold;
}

.list-group-item span {
  background: $primary-color;
  margin-left: 20px;
}