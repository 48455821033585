.btn-primary {
  color: #fff;
  background-color: $primary-color;
  border-color: $primary-color;
}

.btn-primary:hover {
  background-color: darken( $primary-color, 10);
  border-color: darken( $primary-color, 10);
}

strong, h1, h2, h3, h6 {
  color: $primary-color;
}

a {
  color: $primary-color;
}

a:hover {
  color: darken($primary-color, 10)
}