/* patch */

.message-image {
  max-width: 200px;
  max-height: 200px;
}

.message-image {
  border-radius: 4px;
}

